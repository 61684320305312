export enum ErrorLevel {
  Unknown = 0,
  Neutral = 1,
  Success = 2,
  Warning = 3,
  Error = 4
}

export enum LabelType {
  Unknown = 0,
  Contact = 1,
  Distributor = 2,
  Article = 3,
  Vehicle = 4,
  Order = 5
}

export enum FileType {
  Unknown = 0,
  Temp = 1,
  Appointment = 2,
  Bike = 3,
  Contact = 4,
  Event = 5,
  Journal = 6,
  Leasing = 7,
  LeasingGen = 8,
  Logo = 9,
  Retour = 10,
  Supplier = 11,
  Ticket = 12
}

export enum DropdownType {
  Unknown = 0,
  Language = 1,
  Salutation = 2,
  ContactType = 3,
  ContactRelationship = 4,
  ContactCorrespondence = 5,
  Country = 6,
  ProductGroup = 7,
  ProductType = 8
}

export enum ListHelperOperator {
  Contains = "contains",
  Not = "not",
  Equals = "equals",
  LessThan = "lt",
  LessEqual = "le",
  GreaterThan = "gt",
  GreaterEqual = "ge"
}

export enum DataTableColumnFilter {
  Unknown = 0,
  Contains = 1,
  Equals = 2,
  Boolean = 3
}

export enum ProductTypes {
  shoe = 1,
  textile = 2,
  hardware = 3
}
