import Vue from "@vue/compat";
import { createApp } from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import router from "./router";
import store from "@/store/store";
import vClickOutside from "click-outside-vue3";
import { setupI18n } from "@/plugins/i18n";
import iframeResize from "iframe-resizer/js/iframeResizer";
import moment from "moment";
import { createMetaManager } from "vue-meta";
import PrimeVue from "primevue/config";
import vueChartist from 'vue-chartist'

import "@/design/index.scss";
// import '@fortawesome/fontawesome-pro/js/fontawesome'
// import '@fortawesome/fontawesome-pro/js/solid'
// import '@fortawesome/fontawesome-pro/js/regular'
// import '@fortawesome/fontawesome-pro/js/light'
// import '@fortawesome/fontawesome-pro/js/brands'

Vue.configureCompat({
  MODE: 3,
  // required by Vue-router
  CONFIG_OPTION_MERGE_STRATS: "suppress-warning",
  GLOBAL_PRIVATE_UTIL: "suppress-warning",
  GLOBAL_PROTOTYPE: "suppress-warning",

  // required due to global mixin on vue-router
  INSTANCE_EVENT_HOOKS: "suppress-warning",
  OPTIONS_DESTROYED: "suppress-warning",
  INSTANCE_EVENT_EMITTER: "suppress-warning",

  // required by portal-vue
  GLOBAL_SET: "suppress-warning",

  // globals
  GLOBAL_EXTEND: "suppress-warning",
  GLOBAL_MOUNT: "suppress-warning",

  // functional does not work how you think ;)
  COMPONENT_FUNCTIONAL: "suppress-warning",
  RENDER_FUNCTION: "suppress-warning",

  CUSTOM_DIR: "suppress-warning",

  // JF
  INSTANCE_LISTENERS: "suppress-warning",
  ATTR_FALSE_VALUE: "suppress-warning",
  ATTR_ENUMERATED_COERCION: "suppress-warning",
});

// See https://github.com/vuejs/vue-next/pull/4121 for details
const originalVModelDynamicCreated = Vue.vModelDynamic.created;
const originalVModelDynamicBeforeUpdate = Vue.vModelDynamic.beforeUpdate;
Vue.vModelDynamic.created = function (el: any, binding: any, vnode: any) {
  originalVModelDynamicCreated.call(this, el, binding, vnode);
  if (!el._assign) {
    el._assign = () => {
      //
    };
  }
};
Vue.vModelDynamic.beforeUpdate = function (el: any, binding: any, vnode: any) {
  originalVModelDynamicBeforeUpdate.call(this, el, binding, vnode);
  if (!el._assign) {
    el._assign = () => {
      //
    };
  }
};

async function run() {
  const { default: BootstrapVue } = await import("bootstrap-vue/src");
  const PortalVue = await import("portal-vue");
  const { default: App } = await import("./App.vue");
  PortalVue.Portal.options.compatConfig = {
    MODE: 2,
    RENDER_FUNCTION: "suppress-warning",
    INSTANCE_SCOPED_SLOTS: "suppress-warning",
    OPTIONS_BEFORE_DESTROY: "suppress-warning",
  };
  PortalVue.PortalTarget.options.compatConfig = {
    MODE: 2,
    RENDER_FUNCTION: "suppress-warning",
    WATCH_ARRAY: "suppress-warning",
    OPTIONS_BEFORE_DESTROY: "suppress-warning",
    INSTANCE_SCOPED_SLOTS: "suppress-warning",
  };
  PortalVue.Wormhole.$.type.compatConfig = {
    MODE: 3,
    INSTANCE_SET: "suppress-warning",
    INSTANCE_DELETE: "suppress-warning",
  };

  const metaManager = createMetaManager();

  const app = createApp({
    metaManager,
    router,
    ...App,
  });

  const i18n = setupI18n();
  app.use(router);
  app.use(store);
  app.use(i18n);
  app.use(metaManager);
  app.use(vueChartist)

  app.use(PrimeVue);
  app.use(BootstrapVue, {
    formTextControls: {
      autocomplete: "chrome-off",
    },
  });

  app.use(VueSweetalert2);
  app.use(vClickOutside);

  app.directive("resize", {
    beforeMount: (el: any) => {
      el.addEventListener("load", () =>
        iframeResize(
          {
            heightCalculationMethod: "taggedElement",
            checkOrigin: false,
            log: false,
            onMessage(msg: any) {
              if (msg.message) {
                switch (msg.message.action) {
                  case "addLabel":
                    store.dispatch("user/addLabel", {
                      labelType: msg.message.type,
                      foreignKey: msg.message.fk,
                    });
                    break;
                  case "refreshLabels":
                    store.dispatch("user/refreshLabels", {
                      labelType: msg.message.type,
                    });
                    break;
                  case "sidebar":
                    store.dispatch("navigation/openUrl", msg.message);
                    break;
                  case "collapseSidebar":
                    store.dispatch("navigation/collapseSidebar");
                    break;
                  case "changelog":
                    store.dispatch("user/refreshCounters");
                    break;
                  case "toast":
                    this.$showToast(
                      msg.message.title,
                      msg.message.msg,
                      msg.message.variant
                    );
                    break;
                  default:
                    console.warn("Unknown iframeResize::Message", msg.message);
                    break;
                }
              }
            },
          },
          el
        )
      );
    },
    unmounted: (el: any) => {
      el.iFrameResizer.removeListeners();
    },
  });

  /**
   * Prototypes
   */
  app.config.globalProperties.i18n = i18n;
  app.config.globalProperties.$moment = moment;
  app.config.globalProperties.$showToast = function (
    title: string,
    msg: string,
    variant: string
  ) {
    this.$bvToast.toast(msg, {
      title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant ?? "default",
      solid: true,
      toaster: "b-toaster-top-center",
    });
  };
  app.config.globalProperties.$formLabel = function (
    label: string,
    field: any
  ) {
    const { required, requiredIf } = field;
    if (required || (requiredIf && !requiredIf.$response)) {
      return this.$t(label) + " *";
    }
    return this.$t(label);
  };

  app.mount("#app");
}

run();
