import api from "@/config/Api.js";
import moment from "moment";

function findStoredUser() {
  try {
    return JSON.parse(localStorage.getItem("auth.currentUser"));
  } catch (e) {
    return {};
  }
}

const getDefaultState = () => {
  return {
    currentUser: findStoredUser(),
    accessTokenExpires: localStorage.getItem("auth.accessTokenExpires"),
    interval: null,
  };
};

export const state = getDefaultState();

export const getters = {
  /**
   * Whether the user is currently logged in.
   * @param {*} state
   * @returns
   */
  loggedIn(state) {
    return state.accessTokenExpires != null;
  },
};

export const actions = {
  /**
   * This is automatically run in `src/state/store.js` when the app
   * starts, along with any other actions named `init` in other modules.
   *
   * @param {*} param0
   */
  init({ dispatch }) {
    dispatch("validate");
  },

  /**
   * Reset the State of this module
   * @param {*} param0
   */
  reset({ commit }) {
    commit("RESET");
  },

  /**
   * Logs in the current user.
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  logIn({ commit, dispatch, getters }, { email, password, i18n } = {}) {
    if (getters.loggedIn) return dispatch("validate");
    return api
      .post("/token/login", {
        username: email,
        password: password,
      })
      .then((res) => {
        const user = res.data.data.user;
        commit("SET_CURRENT_USER", user);
        commit("SET_ACCESS_TOKEN_EXPIRES", res.data.data.accessTokenExpires);

        if (user && user.language !== this.state.language.currentLanguage) {
          dispatch(
            "language/changeLanguage",
            {
              language: user.language,
              i18n,
            },
            { root: true }
          );
        }

        return true;
      });
  },

  /**
   * Logs out the current user
   *
   * @param {*} param0
   */
  logOut({ commit }) {
    return new Promise((resolve) => {
      commit("SET_CURRENT_USER", {});
      commit("SET_ACCESS_TOKEN_EXPIRES", null);
      commit("CLEAR_TIMER");
      resolve();
    });
  },

  /**
   * Sends a request to the API to email the user a password recovery link
   *
   * @param {*} param0
   */
  forgotPassword({ _ }, { email }) {
    return api
      .post("/token/forgotpassword", { email: email })
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  },

  /**
   * Refresh the Token if needed
   *
   * @param {*} param0
   */
  refreshToken({ dispatch, state }) {
    if (state.accessTokenExpires) {
      let expirationDate = moment(state.accessTokenExpires);
      let now = moment().add(1, "hours");
      if (now > expirationDate) {
        return dispatch("executeRefreshToken");
      } else {
        return Promise.resolve(null);
      }
    } else {
      return Promise.resolve(null);
    }
  },

  /**
   * Execute the refresh token
   * @param {*} param0
   * @returns
   */
  executeRefreshToken({ commit }) {
    return api
      .get("/token/refresh")
      .then((res) => {
        commit("SET_ACCESS_TOKEN_EXPIRES", res.data.data.accessTokenExpires);
        return true;
      })
      .catch((e) => {
        if (console) console.error(e);
      });
  },

  /**
   * Validates the current user's token and refreshes it
   * with new data from the API.
   *
   * @param {*} param0
   * @returns
   */
  validate() {
    return new Promise(function (resolve) {
      resolve(localStorage.getItem("auth.accessTokenExpires") != null);
    });
  },

  startTimer({ commit, dispatch }) {
    // clear the interval first
    dispatch("clearTimer").then(() => {
      commit("START_TIMER", () => {
        dispatch("refreshToken");
      });
    });
  },

  clearTimer({ commit }) {
    commit("CLEAR_TIMER");
  },
};

export const mutations = {
  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
    if (user == {} || user == null || typeof user == "undefined") {
      localStorage.removeItem("auth.currentUser");
    } else {
      localStorage.setItem("auth.currentUser", JSON.stringify(user));
    }
  },

  SET_ACCESS_TOKEN_EXPIRES(state, newValue) {
    state.accessTokenExpires = newValue;
    if (newValue == null) {
      localStorage.removeItem("auth.accessTokenExpires");
    } else {
      localStorage.setItem("auth.accessTokenExpires", newValue);
    }
  },

  START_TIMER(state, func) {
    state.interval = setInterval(() => {
      func();
    }, 30000);
  },

  CLEAR_TIMER(state) {
    clearInterval(state.interval);
    state.interval = null;
  },
};
