import api from "@/config/Api.js";
import { loadLocaleMessages, setI18nLanguage } from "@/plugins/i18n";

const getDefaultState = () => {
  let availableLanguages = [
    {
      langKey: "de",
      langIcon: require("@/assets/images/flags/germany_flag.jpg"),
      langName: "Deutsch",
      langShort: "DE"
    },
    {
      langKey: "en",
      langIcon: require("@/assets/images/flags/gb_flag.jpg"),
      langName: "English",
      langShort: "EN"
    }
    /*
    {
      langKey: 'fr',
      langIcon: require('@/assets/images/flags/french_flag.jpg'),
      langName: 'Français',
      langShort: "FR"
    },
    */
  ];

  if (process.env.NODE_ENV !== "production") {
    availableLanguages.push({
      langKey: "te",
      langIcon: require("@/assets/images/flags/test_flag.jpg"),
      langName: "Test",
      langShort: "XX"
    });
  }
  return {
    availableLanguages,
    currentLanguage: localStorage.getItem("lang") ?? "de"
  };
};

export const state = getDefaultState();

export const getters = {};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },

  changeLanguage({ commit, state, dispatch }, { language, updateRemote, i18n }) {
    if (language) {
      let usedLanguage = null;
      if (state.availableLanguages.some(el => el.langKey == language)) {
        usedLanguage = language;
        loadLocaleMessages(i18n, language).then(() => {
          commit("CHANGE_LANGUAGE", language);
        });
    } else {
        usedLanguage = "de";
        commit("CHANGE_LANGUAGE", usedLanguage);
      }
      // Update the user remote
      if (
        updateRemote &&
        usedLanguage &&
        usedLanguage != this.state.language.currentLanguage
      ) {
        return api
          .post("/user/settings/language", {
            lang: usedLanguage
          })
          .then(res => {
            // Reload the iframe after the user was refreshed
            dispatch("navigation/updateRouterKey", true, { root: true });
            if (res.data.data == true) {
              return true;
            }
            return false;
          })
          .catch(e => {
            if (console) console.error(e);
          });
      }
    }
    return new Promise(resolve => {
      resolve(false);
    });
  }
};

export const mutations = {
  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  CHANGE_LANGUAGE(state, language) {
    state.currentLanguage = language;
    localStorage.setItem("lang", language);
  }
};
