const getDefaultState = () => {
  return {
    layoutType: "vertical"
  };
};

export const state = getDefaultState();

export const getters = {};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },

  changeLayoutType({ commit }, { layoutType }) {
    commit("CHANGE_LAYOUT", layoutType);
  }
};

export const mutations = {
  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  }
};
