import api from "@/config/Api.js";

const getDefaultState = () => {
  return {
    currentInstance: null,
    availableInstances: []
  };
};

export const state = getDefaultState();

export const getters = {};

export const actions = {
  reset({ commit }) {
    commit("RESET");
  },

  fetchAvailableInstances({ commit }) {
    return api
      .get("/company/instance/availableInstances")
      .then(res => {
        if (res.data.data && state.availableInstances !== res.data.data) {
          commit("SET_AVAILABLE_INSTANCES", res.data.data);
        }
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  fetchCurrentInstance({ commit }) {
    return api
      .get("/company/instance/SidebarInstanceinformation")
      .then(res => {
        if (res.data.data && state.currentInstance !== res.data.data.company) {
          commit("SET_CURRENT_INSTANCE", res.data.data.company);
        }
      })
      .catch(e => {
        if (console) console.error(e);
      });
  },

  changeInstance({ commit }, { instance }) {
    return api
      .post("/company/instance/switchInstance", {
        dbs_id: instance.dbs_id
      })
      .then(res => {
        if (res.data.data) {
          commit("RESET");
        }
      })
      .catch(e => {
        if (console) console.error(e);
      });
  }
};

export const mutations = {
  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  SET_AVAILABLE_INSTANCES(state, items) {
    state.availableInstances = items;
  },

  SET_CURRENT_INSTANCE(state, instance) {
    state.currentInstance = instance;
  }
};
