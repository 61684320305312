import store from "@/store/store";
import axios from "axios";
import router from "@/router";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
});

api.interceptors.request.use(
  (request) => {
    request.baseURL += process.env.VUE_APP_BASEPATH;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      if (error.config.url === "/token/refresh") {
        return store.dispatch("auth/logOut").then(() => {
          if (["login", "logout"].some((m) => m == router.name)) {
            router
              .push({
                name: "login",
              })
              .catch(() => {
                //
              });
          } else {
            router
              .push({
                name: "login",
                query: { redirectFrom: router.name },
              })
              .catch(() => {
                //
              });
          }
          return Promise.reject(error);
        });
      } else {
        return store.dispatch("auth/executeRefreshToken").then((res) => {
          if (res) {
            return axios(error.config);
          }
          return Promise.reject(error);
        });
      }
    }
    return Promise.reject(error);
  }
);

/*
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (originalRequest.url === '/token/refresh') {
      originalRequest._retry = true;
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .get('/' + process.env.VUE_APP_BASEPATH + '/token/refresh')
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem(
              'auth.accessTokenExpires',
              res.data.data.accessTokenExpires
            );
            return axios(originalRequest);
          }
        })
        .catch(() => {
          localStorage.removeItem('auth.accessTokenExpires');
          if (["login", "logout"].some(m => m == router.name)) {
            router.push({
              name: "login"
            }).catch(() => {
              //
            });
          } else {
            router.push({
              name: "login",
              query: { redirectFrom: router.name }
            }).catch(() => {
              //
            });
          }
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);
*/

export default api;
