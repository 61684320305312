import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/store.js';
import routes from './routes';

const router = createRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  history: createWebHistory(),
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some(route => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  if (routeTo?.path && ! routeTo?.path.startsWith('/org/')) {
    store.dispatch("navigation/openRouterUrl", routeTo.path);
  }
  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Refresh the token
    return store.dispatch('auth/refreshToken').then(() => {
      // Validate the local user token...
      return store.dispatch('auth/validate').then(validUser => {
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        validUser || ['login', 'logout'].some(m => m == routeFrom.name) ? next() : redirectToLogin();
      });
    });
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin();

  function redirectToLogin() {
    // Pass the original route to the login component
    if (['login', 'logout'].some(m => m == routeTo.name)) {
      next({ name: 'login' });
    } else {
      next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
    }
  }
});

// Check the path for V5 routes
router.afterEach((routeTo, routeFrom) => {
  if (
    routeTo?.path &&
    ! routeTo.path.startsWith('/org/') &&
    ! routeTo.path.startsWith('/wiki') &&
    ! routeTo.path.startsWith('/login') &&
    ! routeTo.path.startsWith('/forgot-password') &&
    ! ['404', '500'].includes(routeTo.name)
  ) {
    store.dispatch("navigation/checkNavigation", routeTo.path).then(allowed => {
      if (allowed === false && routeTo.path != "/home") {
        router.push({ path: "/home" });
      }
    });
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.beforeEnter) {
          route.beforeEnter(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve(null);
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve(null);
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export default router;
