import store from "@/store/store";
import { LabelType } from "@/models/Enums";

export default [
  {
    path: "/",
    component: () => import("@/components/layouts/vertical"),
    beforeEnter(routeTo, routeFrom, next) {
      // Restore the LabelType
      store.dispatch("user/setLabelType", LabelType.Unknown);

      store.dispatch("auth/validate").then((res) => {
        if (res) {
          next();
        } else {
          next({ name: "logout" });
        }
      });
    },
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "/org/:men_link",
        //   component: () => import("@/views/old"),

        component: () => import("@/views/home.vue"),
        beforeEnter(routeTo, routeFrom, next) {
          next();
        },
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/org/:men_link/:men_tool",
        component: () => import("@/views/old"),
        beforeEnter(routeTo, routeFrom, next) {
          switch (routeTo.path) {
            case "/org/adressen/contact":
              store.dispatch("user/setLabelType", LabelType.Contact);
              break;
            case "/org/adressen/lieferanten":
              store.dispatch("user/setLabelType", LabelType.Distributor);
              break;
            case "/org/lager/artikelstamm":
              store.dispatch("user/setLabelType", LabelType.Article);
              break;
            case "/org/lager/fahrzeuglager":
              store.dispatch("user/setLabelType", LabelType.Vehicle);
              break;
            case "/org/lager/order":
              store.dispatch("user/setLabelType", LabelType.Order);
              break;
            default:
              store.dispatch("user/setLabelType", LabelType.Unknown);
              break;
          }
          next();
        },
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/apikeys",
        name: "API Key Settings",
        component: () => import("@/views/apikeys"),
        meta : {
          authRequired: true
        }
      },
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/home.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("@/views/home.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("@/views/employees"),
        meta: {
          authRequired: true,
        }
      },
      {
        path: "/vendors",
        name: "Vendors",
        component: () => import("@/views/vendors"),
        meta: {
          authRequired: true
        }
      },
      {
        path: "/dealers",
        name: "Dealers",
        component: () => import("@/views/dealers"),
        meta: {
          authRequired: true
        }
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/views/products/dealer.vue"),
        meta: {
          authRequired: true
        }
      },
      {
        path: "/products/:id/:type?",
        name: "products-form",
        component: () => import("@/views/products/form.vue"),
        meta: {
          authRequired: true
        }
      },
      {
        path: "/settings/changelog",
        name: "changelog",
        component: () => import("@/views/settings/changelog.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/functions",
        name: "functions",
        component: () => import("@/views/settings/functions.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/company",
        name: "Company",
        component: () => import("@/views/company")
      },
      {
        path: "/settings/profile",
        name: "profile",
        component: () => import("@/views/settings/profile.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/group-mapping",
        name: "group-mapping",
        component: () => import("@/views/settings/group-mapping.vue"),
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/settings/product-groups",
        name: "product-groups",
        component: () => import("@/views/settings/product-groups.vue"),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: "/wiki",
    name: "wiki",
    component: () => import("@/components/layouts/vertical-wiki"),
    beforeEnter(routeTo, routeFrom, next) {
      // Restore the LabelType
      store.dispatch("user/setLabelType", LabelType.Unknown);

      next();
    },
    meta: {
      authRequired: true,
      title: "Wiki",
    },
    children: [
      {
        path: "/wiki",
        name: "wiki-index",
        component: () => import("@/views/wiki/index.vue"),
        meta: {
          authRequired: true,
          title: "Wiki",
        },
      },
      {
        path: "/wiki/detail/:page_id/:nice_path?",
        name: "wiki-deatil",
        component: () => import("@/views/wiki/detail.vue"),
        meta: {
          authRequired: true,
          title: "Wiki",
        },
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () => import("@/views/account/forgot-password")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/account/login"),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      store.dispatch("auth/validate").then((res) => {
        if (res) {
          next({ name: "Home" });
        } else {
          next();
        }
      });
    },
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("auth/logOut");

      store.dispatch("auth/reset");
      store.dispatch("company/reset");
      store.dispatch("language/reset");
      store.dispatch("layout/reset");
      store.dispatch("navigation/reset");
      store.dispatch("user/reset");
      next({ name: "login" });
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("@/views/error/404"),
  },
];
